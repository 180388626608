import "core-js/stable";
import "regenerator-runtime/runtime";
import 'element-closest-polyfill';
import 'whatwg-fetch';
import 'element-remove';
import 'datalist-polyfill';

import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["filterSelect", "filters", "noResults", "getLinkButton", "datalistInput", "clearFiltersButton", "filterLoader", "getLinkButton", "searchInput", "generateUrl", "linkContainer", "urlParams", "initial", "allResultsContainer", "paginationContainerTop", "sortableTable", "sortableContainer", "showSortableChartButton", "hideSortableChartButton", "specValue", "paginationContainer", "partTypeContent", "typeContainer"]

  initialize() {
    if (!this.data.has("initialized")) {
      let filterPage = this.data.get('filterpage');
      let filterURL = this.data.get('url');
      if (document.body.scrollWidth >= 720) {
        this.filtersTarget.checked = true;
      }
      this.initialFilters();
      this.loadData(filterURL, this.loadFilters);
    }
    this.data.set('initialized', true);
  }
  initialFilters() {
    let filterList = JSON.parse(this.data.get('list'));
    let filterPage = this.data.get('filterpage');
    let filterHistory = this.data.get('historyurl');
    const itemType = filterPage == "tech" ? "tech" : "parts";
    const uri = window.location.toString();
    if (uri.indexOf("?") > 0) {
      const clean_uri = uri.substring(0, uri.indexOf("?"));
      let eArray = [];
      for (let f of filterList) {
        if (f != "hp" && f != "video" && f != "zp") {
          sessionStorage.setItem(filterPage + "|" + f, JSON.stringify(eArray));
        }
      }
      let hpCheckbox = document.getElementById('hp_filter');
      if (hpCheckbox && hpCheckbox.checked == false) {
        sessionStorage.setItem(filterPage + "|hp", "false");
      } else {
        sessionStorage.setItem(filterPage + "|hp", "true");
      }
      let videoCheckbox = document.getElementById('video_filter');
      if (videoCheckbox && videoCheckbox.checked == false) {
        sessionStorage.setItem(filterPage + "|video", "false");
      } else {
        sessionStorage.setItem(filterPage + "|video", "true");
      }
      let zpCheckbox = document.getElementById('zp_filter');
      if (zpCheckbox && zpCheckbox.checked == false) {
        sessionStorage.setItem(filterPage + "|zp", "false");
      } else {
        sessionStorage.setItem(filterPage + "|zp", "true");
      }
      if (uri.indexOf("sortable") > 0) {

        this.data.set("sortable", "true");
        var url = new URL(uri);
        this.data.set("sortableType", [url.searchParams.get("sortable")]);
        sessionStorage.setItem(filterPage + '|sortable', "true");
        sessionStorage.setItem(filterPage + '|sortableType', JSON.stringify([url.searchParams.get("sortable")]));
      }
      window.history.replaceState({}, document.title, clean_uri);
    }

    let applied = false;
    for (let f of filterList) {
      if (f != "hp" && f != "video" && f != "zp") {
        let select = this.filterSelectTargets.filter(r => r.dataset.jsontype == f)[0];
        let stored = this.setSelections(f, select, filterPage);
        if (stored && stored.length) {
          applied = true
        }
        this.selectOptions(select, stored);
      } else if (f == "hp") {
        let hpInput = document.getElementById("hp_filter");
        let storageHpName = filterPage + '|hp';
        let storedHp = JSON.parse(sessionStorage.getItem(storageHpName));
        if (storedHp != null) {
          this.data.set("hp", storedHp.toString());
          if (storedHp == true) {
            hpInput.checked = true;
            hpInput.closest('label').classList.add('hp-filter-active');
            applied = true
          } else {
            hpInput.checked = false;
            hpInput.closest('label').classList.remove('hp-filter-active');
          }
        }
      } else if (f == "video") {
        let videoInput = document.getElementById("video_filter");
        let storagevideoName = filterPage + '|video';
        let storedvideo = JSON.parse(sessionStorage.getItem(storagevideoName));
        if (storedvideo != null) {
          this.data.set("video", storedvideo.toString());
          if (storedvideo == true) {
            videoInput.checked = true;
            videoInput.closest('label').classList.add('video-filter-active');
            applied = true;
          } else {
            videoInput.checked = false;
            videoInput.closest('label').classList.remove('video-filter-active');
          }
        }
      } else if (f == "zp") {
        let zpInput = document.getElementById("zp_filter");
        let storagezpName = filterPage + '|zp';
        let storedzp = JSON.parse(sessionStorage.getItem(storagezpName));
        if (storedzp != null) {
          this.data.set("zp", storedzp.toString());
          if (storedzp == true) {
            zpInput.checked = true;
            zpInput.closest('label').classList.add('zp-filter-active');
            applied = true;
          } else {
            zpInput.checked = false;
            zpInput.closest('label').classList.remove('zp-filter-active');
          }
        }
      }
    }
    this.initializeDatalists(this.datalistInputTargets);



    if (applied == true) {
      this.filterCards(false);
    }

  }


  selectOptions(select, stored) {
    const allOptions = Array.from(select.querySelectorAll('option'));
    let selectedOptions;
    if (stored != null) {
      let selectedOptions = allOptions.filter(o => stored.includes(isNaN(o.value) ? o.value : parseFloat(o.value)))
      for (let o of selectedOptions) {
        o.selected = 'selected';
      }
    }
  }

  loadData(url, callback) {
    fetch(url)
      .then(response => { return response.json(); })
      .then(json => { callback.call(this, json); return json })
  }

  loadFilters(data) {
    let filterList = JSON.parse(this.data.get('list'));
    let filterPage = this.data.get('filterpage');
    let filterHistory = this.data.get('historyurl');
    const itemType = filterPage == "tech" ? "tech" : "parts";
    const items = JSON.parse(data[itemType]).data;

    for (let f of filterList) {
      if (f != "hp" && f != "video" && f != "zp") {
        let ids = [];
        if (f == "units") {
          ids = items.map(u => u.attributes.unit_relationships).reduce((acc, val) => acc.concat(val), []).map(u => parseInt(u.unit_id));
          ids = Array.from(new Set(ids.map(JSON.stringify))).map(JSON.parse).sort();
        } else if (f == "types") {
          ids = items.map(u => u.attributes.part_types).reduce((acc, val) => acc.concat(val), []).filter(Boolean).filter((v, i, a) => a.indexOf(v) === i);
          this.prepPartTypes(ids, JSON.parse(data["types"]), filterPage);
        } else {
          ids = items.map(u => u.attributes[f]).reduce((acc, val) => acc.concat(val), []).filter(Boolean).filter((v, i, a) => a.indexOf(v) === i);
        }
        let select = this.filterSelectTargets.filter(r => r.dataset.jsontype == f)[0];
        this.getOptions(select, ids);
      } else if (f == "hp") {
        let hpInput = document.getElementById("hp_filter");
        let storageHpName = filterPage + '|hp';
        let storedHp = JSON.parse(sessionStorage.getItem(storageHpName));
        if (storedHp != null) {
          this.data.set("hp", storedHp.toString());
          if (storedHp == true) {
            hpInput.checked = true;
            hpInput.closest('label').classList.add('hp-filter-active');
          } else {
            hpInput.checked = false;
            hpInput.closest('label').classList.remove('hp-filter-active');
          }
        }
      } else if (f == "video") {
        let videoInput = document.getElementById("video_filter");
        let storagevideoName = filterPage + '|video';
        let storedvideo = JSON.parse(sessionStorage.getItem(storagevideoName));
        if (storedvideo != null) {
          this.data.set("video", storedvideo.toString());
          if (storedvideo == true) {
            videoInput.checked = true;
            videoInput.closest('label').classList.add('video-filter-active');
          } else {
            videoInput.checked = false;
            videoInput.closest('label').classList.remove('video-filter-active');
          }
        }
      } else if (f == "zp") {
        let zpInput = document.getElementById("zp_filter");
        let storagezpName = filterPage + '|zp';
        let storedzp = JSON.parse(sessionStorage.getItem(storagezpName));
        if (storedzp != null) {
          this.data.set("zp", storedzp.toString());
          if (storedzp == true) {
            zpInput.checked = true;
            zpInput.closest('label').classList.add('zp-filter-active');
          } else {
            zpInput.checked = false;
            zpInput.closest('label').classList.remove('zp-filter-active');
          }
        }
      }
    }



    if (itemType == "tech") {
      this.loadTech(items);
    } else {
      this.loadParts(items);
    }

  }

  setSelections(filter, select, filterPage) {
    let storageName = filterPage + '|' + filter;
    let params = select.dataset.paramdata;
    if (params) {
      let pArray = [];
      if (params.includes('|')) {
        for (let p of params.split('|')) {
          if (filter == 'types' || filter == 'rtype' || filter == 'author') {
            pArray.push(decodeURI(p));

          } else {
            pArray.push(parseInt(p));
          }
        }
      } else {
        if (filter == 'types' || filter == 'rtype' || filter == 'author') {
          pArray.push(decodeURI(params));
        } else {
          pArray.push(parseInt(params));
        }
      }
      sessionStorage.setItem(storageName, JSON.stringify(pArray));
    }
    let stored = JSON.parse(sessionStorage.getItem(storageName));
    if (stored != null) {
      this.data.set(filter, JSON.stringify(stored));
    }
    return stored
  }

  initializeDatalists(lists) {
    lists.forEach(dl => {
      const list = dl.parentNode.querySelector("#" + dl.getAttribute("list"));
      let select = dl.parentNode.querySelector("#" + dl.dataset.datalistfor);
      if (dl.dataset.datalisttype == "multiple" && select.value != '') {
        const label = document.querySelector("label[for=" + dl.dataset.datalistfor + "]");
        select.querySelectorAll('option:checked').forEach(so => {
          dl.insertAdjacentHTML('beforebegin', "<div class='datalist-selections' data-label='" + so.innerHTML + "' data-dlinput='" + dl.name + "' data-list='#" + dl.getAttribute("list") + "' data-select='#" + dl.dataset.datalistfor + "' data-value='" + so.value + "' data-action='click->filter#removeDatalistOption'>" + so.innerHTML + " &#9447;</div>");
        });
      } else {
        const sv = select.querySelector("option[value='" + select.value + "']");
        if (sv) {
          dl.value = sv.innerHTML;
        }
      }
    })
  }

  getOptions(select, ids) {
    const allOptions = Array.from(select.querySelectorAll('option'));
    let removeOptions = allOptions.filter(o => !ids.includes(isNaN(o.value) ? o.value : parseFloat(o.value)));
    for (let o of removeOptions) {
      o.remove();
    }
    if ("listname" in select.dataset) {
      const dl = document.getElementById(select.dataset.listname);
      const dlOptions = Array.from(dl.querySelectorAll('option'));
      let removeDlOptions = dlOptions.filter(o => !ids.includes(isNaN(o.dataset.value) ? o.dataset.value : parseFloat(o.dataset.value)));
      for (let o of removeDlOptions) {
        o.remove();
      }
    }
    select.setAttribute('data-hasResults', JSON.stringify(ids))
  }

  loadTech(resources) {
    let items = "";
    let limit = parseInt(this.data.get('limit'));
    for (let r of resources.slice(limit - 1)) {
      if (!document.getElementById(r.id)) {
        items += this.createTechCard(r);
      }
    }
    this.allResultsContainerTarget.insertAdjacentHTML("beforeend", items);

    this.filterCards(true);
  }

  loadParts(parts) {
    let newLimit = new Date();
    newLimit.setMonth(newLimit.getMonth() - 3);
    let items = "";
    let limit = parseInt(this.data.get('limit'));
    if (limit > 0) {
      for (let p of parts.slice((limit - 1))) {
        items += this.createPartCard(p, newLimit);
      }
      this.allResultsContainerTarget.insertAdjacentHTML("beforeend", items);
    }

    this.filterCards(true);
  }

  createTechCard(r) {
    let name = r.attributes.author == "Sonnax" ? '' : r.attributes.author;
    let video = r.attributes.resource_type == 'video' || r.attributes.resource_type == 'webinar' ? true : false;
    const card =
      `<article class="card" data-target="filter.card" data-id="${r.id}" data-video="${video}" data-title="${r.attributes.title}" data-author="${r.attributes.author}" data-subjects="${JSON.stringify(r.attributes.subjects)}" data-rtype="${r.attributes.resource_type}" data-category="${JSON.stringify(r.attributes.category)}" data-makes="${JSON.stringify(r.attributes.makes)}" data-runits="${JSON.stringify(r.attributes.runits)}">
      ${r.attributes.resource_type == "video" ? '<svg class="icon icon-video-logo-web span-2 last"><use xlink:href="#icon-video-logo-web"></use></svg><span class="mls"></span>' : ''}
      <p class="omega">${r.attributes.created}</p>
      <h3 class="serif"><a href="/tech_resources/${r.id}-${r.attributes.title.toLowerCase().replace(/[\W_]+/g, "-")}">${r.attributes.title}</a></h3>
      ${r.attributes.author == "Sonnax" ? '' : '<p class="omega">'}${name}${r.attributes.author == "Sonnax" ? '' : '</p>'}
    </article>`
    return card
  }

  createPartCard(p, newLimit) {
    let stateDate = p.attributes.aasm_state_date.split('-');
    let newPart = new Date(stateDate[0], stateDate[1], stateDate[2]);
    let unitNames = p.attributes.unit_relationships.map(u => u.unit_name).sort().join(', ');
    let traits = p.attributes.traits.map(t => "<li><b>" + t.name + ":</b> <span data-label='" + t.name + "' data-target='filter.specValue' data-dvalue='" + t.value + "' data-uom=''>" + t.value + "</span></li>");
    //let dimensions = p["attributes"].dimensions.map(d => "<li><b>" + d.name.split(' (')[0] + ":</b> <span data-label='" + d.name + "' data-target='filter.specValue' data-uom=' " + d.default_unit + "' data-dvalue='" + (parseInt(d.precision) > 0 ? Number.parseFloat(d.value).toPrecision(parseInt(d.precision)) : Number.parseFloat(d.value)) + "'>" + (parseInt(d.precision) > 0 ? Number.parseFloat(d.value).toPrecision(parseInt(d.precision)) : Number.parseFloat(d.value)) + (d.default_unit == 'in' ? '\"' : ' ' + d.default_unit) + "</span></li>");
    let dimensions = p["attributes"].dimensions.map(d => "<li><b>" + d.name.split(' (')[0] + ":</b> <span data-label='" + d.name + "' data-target='filter.specValue' data-uom=' " + d.default_unit + "' data-dvalue='" + Number.parseFloat(d.value).toFixed(parseInt(d.precision)) + "'>" + Number.parseFloat(d.value).toFixed(parseInt(d.precision)) + (d.default_unit == 'in' ? '\"' : ' ' + d.default_unit) + "</span></li>");

    let hasIcons = (p.attributes.high_performance == true || newPart > newLimit || p.attributes.is_randr == true || p.attributes.warranty_only == true || p.attributes.zip_valve == true) ? true : false;
    const card =
      `<article class="card" data-target="filter.card" data-id="${p.id}" data-name="${p.attributes.name}" data-areas="[${p.attributes.areas}]" data-types="${p.attributes.part_types}" data-category="[${p.attributes.category}]" data-hp="${p.attributes.high_performance}" data-zp="${p.attributes.zip_valve}" data-makes="${JSON.stringify(p.attributes.makes)}" data-units="${JSON.stringify(p.attributes.unit_relationships.map(u => u.unit_id))}">
      <div class="part-card-data">
        <div class="part-image">
          <a href='/parts/${p.id}-${p.attributes.name.toLowerCase().replace(/[\W_]+/g, "-")}'><img src="${p.attributes.main_img_url}" class="image-part-card" alt="${p.attributes.unit_relationships.map(u => u.unit_name).sort()} ${p.attributes.name} ${p.attributes.number}"/></a>
        </div>
        <div class="card-text">
          
            
          ${((unitNames.length > 0) && !p.attributes.part_types.includes("Valve Body")) ? '<h3 class="unit-list">' + p.attributes.unit_relationships.sort(u => u.unit_name).map(u => '<a href="/units/' + u.unit_id + '-' + u.unit_name.toLowerCase().replace(/[\W_]+/g, "-") + '">' + u.unit_name + '</a>').join(' ') + '</h3>' : ''}
          <h2><a href='/parts/${p.id}-${p.attributes.name.toLowerCase().replace(/[\W_]+/g, "-")}'>${p.attributes.part_types.includes("Valve Body") ? unitNames + " " : ""}${p.attributes.name}</a> <small class="nowrap light">${p.attributes.number}</small></h2>
          ${newPart > newLimit ? '<div class="side-icons"><svg><use xlink:href="#icon-new-part-logo"></use></svg></div>' : ''}
          ${p.attributes.high_performance == true ? '<div class="side-icons"><svg><use xlink:href="#icon-HP-logo"></use></svg></div>' : ''}
          ${p.attributes.is_randr == true ? '<div class="side-icons"><svg><use xlink:href="#icon-r_and_r_only"></use></svg></div>' : ''}
          ${p.attributes.warranty_only == true ? '<div class="side-icons"><svg><use xlink:href="#icon-warranty_only"></use></svg></div>' : ''}
          ${p.attributes.zip_valve == true ? '<div class="side-icons"><svg><use xlink:href="#icon-Zip-Drop-In-Valve-Icon"></use></svg></div>' : ''}
          <div class="${hasIcons == true ? 'side-iconbox' : ''}">
            ${p.attributes.coming_soon == true ? '<h4 class="inline coming_soon">Coming Soon</h4>' : ''}
            ${String(p.attributes.application_info).length > 0 && p.attributes.application_info ? '<p class="i">' + p["attributes"].application_info + '</p>' : ''}
            ${p.attributes.warranty_only == true ? '<p class="omega">Sonnax rebuilt valve body ' + p["attributes"].number + ' is no longer in production. Please check with your distributor for availability. For service inquiries, please see details on Sonnax’s <a href="/about#warranty">Limited Lifetime Warranty</a> on remanufactured valve bodies.</p>' : ''}
            <div class="optional-info">
              
              ${((traits.length != 0) || (dimensions.length != 0)) ? '<ul>' + traits.join(' ') + dimensions.join(' ') + '</ul>' : ''}
              ${p.attributes.symptoms.map(s => s.name).length != 0 ? '<ul class="divided-bullet"><b><i>Helps cure:</b></i> ' + p.attributes.symptoms.map(s => '<span>' + s.name + '</span>').join(' ') + '</ul>' : ''}
            </div>
          </div>
        </div>
      </div>
    </article>`
    return card
  }

  changeBooleanFilter(e) {
    this.updateFilter(e.target);
  }

  changeFilters(e) {
    let dl = e.target;
    const list = document.querySelector("#" + dl.getAttribute("list"));
    const selectedOption = list.querySelector("option[value='" + dl.value + "']");
    const filterInput = dl.parentNode.querySelector("#" + dl.dataset.datalistfor);

    if (selectedOption) {
      filterInput.querySelector("option[value='" + selectedOption.dataset.value + "']").selected = true;
    }

    if (dl.dataset.datalisttype == "multiple" && dl.value != '' && selectedOption) {
      const label = dl.parentNode.querySelector("label[for=" + this.data.get('formtype') + "_" + dl.dataset.datalistfor + "]");
      dl.insertAdjacentHTML('beforebegin', "<div class='datalist-selections' data-label='" + dl.value + "' data-dlinput='" + dl.name + "' data-list='#" + dl.getAttribute("list") + "' data-select='#" + dl.dataset.datalistfor + "' data-value='" + selectedOption.dataset.value + "' data-action='click->filter#removeDatalistOption'>" + dl.value + " &#9447;</div>")
      selectedOption.disabled = true;
      dl.value = '';
    }

    this.updateFilter(filterInput);
  }

  updateFilter(filterInput) {

    let filterName = filterInput.dataset.jsontype;
    let filterPage = this.data.get('filterpage');
    let filterValues = [];
    if (filterInput.nodeName == 'SELECT') {
      for (let o of filterInput.selectedOptions) {
        let value = isNaN(o.value) ? o.value : parseFloat(o.value);
        filterValues.push(value)
      };
    } else {
      let value = isNaN(filterInput.value) ? filterInput.value : parseFloat(filterInput.value);
      filterValues.push(value)
    }

    if (filterName == "hp") {
      let hpInput = document.getElementById("hp_filter");
      if (hpInput.checked == true) {
        this.data.set(filterName, "true");
        sessionStorage.setItem(filterPage + '|' + filterName, "true");
        hpInput.closest('label').classList.add('hp-filter-active');
      } else {
        this.data.set(filterName, "false");
        sessionStorage.setItem(filterPage + '|' + filterName, "false");
        hpInput.closest('label').classList.remove('hp-filter-active');
      }
    } else if (filterName == "video") {
      let videoInput = document.getElementById("video_filter");
      if (videoInput.checked == true) {
        this.data.set(filterName, "true");
        sessionStorage.setItem(filterPage + '|' + filterName, "true");
        videoInput.closest('label').classList.add('video-filter-active');
      } else {
        this.data.set(filterName, "false");
        sessionStorage.setItem(filterPage + '|' + filterName, "false");
        videoInput.closest('label').classList.remove('video-filter-active');
      }
    } else if (filterName == "zp") {
      let zpInput = document.getElementById("zp_filter");
      if (zpInput.checked == true) {
        this.data.set(filterName, "true");
        sessionStorage.setItem(filterPage + '|' + filterName, "true");
        zpInput.closest('label').classList.add('zp-filter-active');
      } else {
        this.data.set(filterName, "false");
        sessionStorage.setItem(filterPage + '|' + filterName, "false");
        zpInput.closest('label').classList.remove('zp-filter-active');
      }
    } else {
      this.data.set(filterName, JSON.stringify(filterValues));
      sessionStorage.setItem(filterPage + '|' + filterName, JSON.stringify(filterValues));
    }

    if (filterName == "types" && this.data.get("types") == "[]") {
      this.data.set("sortable", "false");
      sessionStorage.setItem(filterPage + '|sortable', "false");
      this.data.set("sortableType", JSON.stringify(['notset']));
      sessionStorage.setItem(filterPage + '|sortableType', JSON.stringify(['notset']));
    }

    this.filterCards(true);

    this.resetPage();


  }

  removeDatalistOption(e) {
    const so = e.target;
    const list = document.querySelector(so.dataset.list);
    const select = document.querySelector(so.dataset.select);
    const dlSelectedOption = list.querySelector("option[value='" + so.dataset.label + "']");
    const sSelectedOption = select.querySelector("option[value='" + so.dataset.value + "']");
    console.log(sSelectedOption);
    sSelectedOption.selected = false;
    dlSelectedOption.disabled = false;
    so.parentNode.removeChild(so);
    this.updateFilter(select);
  }

  clearAllFilters() {
    let filterPage = this.data.get('filterpage');
    let filterName = "video";
    let videoInput = document.getElementById("video_filter");
    if (videoInput && videoInput.checked == true) {
      this.data.set(filterName, "false");
      sessionStorage.setItem(filterPage + '|' + filterName, "false");
      videoInput.closest('label').classList.remove('video-filter-active');
      videoInput.checked = false;
    }

    this.datalistInputTargets.forEach(filterInput => {
      const select = filterInput.parentNode.querySelector("#" + filterInput.dataset.datalistfor)
      filterInput.value = '';
      select.value = '';
      this.updateFilter(select);
    });
    document.querySelectorAll(".datalist-selections").forEach(d => d.parentNode.removeChild(d))
  }

  generateUrl(e) {
    let filterList = JSON.parse(this.data.get('list'));
    let url = window.location.toString() + "?";
    for (let f of filterList) {
      let fi = JSON.parse(this.data.get(f));
      if (f == "hp") {
        if (fi == true) {
          url += "&high_performance=true";
        }
      } else if (f == "video") {
        if (fi == true) {
          url += "&video=true";
        }
      } else if (f == "zp") {

        if (fi == true) {
          url += "&zp=true";
        }
      } else {
        for (let v of fi) {
          if (f == "units") {
            url += "&unit_relationships[]=" + v;
          } else if (f == "category") {
            url += "&categories[]=" + v;
          } else if (f == "rtype") {
            url += "&types[]=" + v;
          } else if (f == "runits") {
            url += "&units[]=" + v;
          } else {
            url += "&" + f + "[]=" + encodeURI(v);
          }
        }
      }

    }
    let copyText = this.linkContainerTarget;
    copyText.value = url;
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    alert("Copied link");
  }

  filterCards(loaded) {
    this.filterLoaderTargets.forEach(l => {
      l.classList.remove("hidden");
    });

    let filterList = JSON.parse(this.data.get('list'));
    let cards = Array.from(this.allResultsContainerTarget.querySelectorAll('.card'));
    let itemData = [];

    for (let card of cards) {
      let results = [];
      for (let f of filterList) {
        let fi = JSON.parse(this.data.get(f));
        if (f == "hp" || f == "video" || f == "zp") {
          if (fi == true) {
            let values = [];
            values.push(card.dataset[f]);
            if (values.includes(this.data.get(f))) {
              results.push(true)
            } else {
              results.push(false)
            }
          } else {
            results.push(true)
          }
        } else {
          if (fi.length != 0) {

            let values = [];
            if (this.isJson(card.dataset[f]) && typeof JSON.parse(card.dataset[f]) != "boolean") {
              values = JSON.parse(card.dataset[f]);
            } else {
              if (card.dataset[f].includes(',')) {
                for (let v of card.dataset[f].split(',')) (values.push(v))
              } else {
                values.push(card.dataset[f]);
              }
            }

            let intersection = values.filter(v => fi.includes(v));


            if (intersection.length != 0) {
              results.push(true);
            } else {
              results.push(false);
            }
          }
        }
      }
      if (results.includes(false)) {
        card.classList.add('hidden-card');
      } else {
        card.classList.remove('hidden-card');
        card.classList.remove('hidden');
        itemData.push(Object.assign({}, card.dataset));
      }
    }

    this.updateFilterOptions(itemData, filterList);


    if (filterList.includes("types")) {
      let types = JSON.parse(this.data.get('types'));
      let currentTypes = itemData.map(item => item.types).reduce((acc, val) => acc.concat(val), []).filter(Boolean).filter((v, i, a) => a.indexOf(v) === i);
      let intersection = types.filter(v => currentTypes.includes(v));
      let filterPage = this.data.get('filterpage');
      if (intersection.length != 0) {
        this.showPartTypeContent(intersection);
        this.showPartTypeSortButtons(intersection);

      } else {
        this.hidePartTypeContent();
        this.hidePartTypeSortButtons();
        this.showSortableChartButtonTargets.forEach(partType => {
          partType.classList.add('hidden');
        });
        this.hideSortableChartButtonTargets.forEach(partType => {
          partType.classList.add('hidden');
        });

      }

    }
    if (this.data.get("sortable") == "true" && JSON.parse(this.data.get("types")).length != 0 && loaded == true) {
      this.createSortableChart();
    } else {
      if (!this.sortableTableTarget.classList.contains('hidden')) {
        this.removeSortableChart();
      }

      this.paginate();

    }

    if (cards.length != itemData.length) {
      this.getLinkButtonTarget.classList.remove('hidden');
      this.clearFiltersButtonTarget.classList.remove('hidden');
      if (itemData.length == 0 && loaded == true) {
        this.noResultsTarget.classList.remove('hidden');
      } else {
        this.noResultsTarget.classList.add('hidden');
      }
    } else {
      this.getLinkButtonTarget.classList.add('hidden');
      this.clearFiltersButtonTarget.classList.add('hidden');
    }

    if (loaded == true) {
      this.filterLoaderTargets.forEach(l => {
        l.classList.add("hidden");
      });
    }

  }

  showPartTypeContent(names) {
    this.partTypeContentTargets.forEach(partType => {
      let type = partType.dataset.typename;

      if (names.includes(type)) {
        partType.classList.remove('hidden');
      } else {
        partType.classList.add('hidden');
      }
    });
  }

  hidePartTypeContent(names) {
    this.partTypeContentTargets.forEach(partType => {
      partType.classList.add('hidden');
    });
  }

  showPartTypeSortButtons(names) {
    this.showSortableChartButtonTargets.forEach(partType => {
      let type = partType.dataset.typename;
      if (names.includes(type)) {
        partType.classList.remove('hidden');
      } else {
        partType.classList.add('hidden');
      }
    });
  }

  hidePartTypeSortButtons(names) {
    this.showSortableChartButtonTargets.forEach(partType => {
      partType.classList.add('hidden');
    });
  }

  loadSortableButtons(types) {
    let container = this.sortableContainerTarget;
    container.innerHTML += `<button class='button-basic hidden' data-action='filter#removeSortableChart' data-target='filter.hideSortableChartButton'>See All Part Info</button>`;

    for (let t of types) {
      if (t.attributes.name != "Front Cover Hub")
      {
        let addButton = `<button class='button-accent hidden' data-dimensions='` + JSON.stringify(t.attributes.required_dimensions) + `' data-traits='` + JSON.stringify(t.attributes.required_traits) + `' data-action='filter#createSortableChart' data-target='filter.showSortableChartButton' data-typename='` + t.attributes.name + `'>See ` + t.attributes.name + ` Sortable Details Chart</button>`;
        container.innerHTML += addButton;
      }
    }
  }

  createSortableChart(e) {
    this.clearSortableChart();
    let chart;
    let sortableType = this.data.get("sortableType");
    if (!e) {
      if (sortableType) {
        chart = Array.from(this.showSortableChartButtonTargets).filter(b => b.dataset.typename == sortableType)[0];
        if (!chart) {
          console.log("need to load");
        }
      } else {
        chart = Array.from(this.showSortableChartButtonTargets).filter(b => !b.classList.contains('hidden'))[0];
      }
    } else {
      chart = e.target;
    }


    let filterPage = this.data.get('filterpage');
    this.data.set("sortable", "true");
    sessionStorage.setItem(filterPage + '|sortable', "true");
    console.log(chart);
    this.data.set("sortableType", JSON.stringify([chart.dataset.typename]));
    sessionStorage.setItem(filterPage + '|sortableType', JSON.stringify([chart.dataset.typename]));
    let specs = [];
    specs.push(JSON.parse(chart.dataset.traits));

    specs.push(JSON.parse(chart.dataset.dimensions));
    specs = specs.reduce((acc, val) => acc.concat(val), []).sort();
    specs = [...new Set(specs)];

    console.log(specs);
    let table = this.sortableTableTarget;
    table.dataset.tableTablename = this.data.get('filterpage') + sortableType;
    let cards = this.allResultsContainerTarget.querySelectorAll('.card:not(.hidden-card)');
    this.hideSortableChartButtonTarget.classList.remove('hidden');
    table.querySelector('thead tr').innerHTML += "<th data-sort='desc' data-action='click->table#sortTable' data-index='0' >Part No. <span class='sort_indicator'>&varr;</span></th>"
    for (let [i, s] of specs.entries()) {
      table.querySelector('thead tr').innerHTML += "<th data-sort='desc' data-action='click->table#sortTable' data-index='" + (i + 1) + "' >" + s + " <span class='sort_indicator'>&varr;</span></th>"
    }
    cards.forEach(c => {
      let values = [];
      if (this.isJson(c.dataset["types"]) && typeof JSON.parse(c.dataset["types"]) != "boolean") {
        values = JSON.parse(c.dataset["types"]);
      } else {
        values.push(c.dataset["types"]);
      }
      if (values.includes(chart.dataset.typename)) {
        let number = c.querySelector('small').innerHTML;
        let url = c.querySelector('h2 a').href;
        let specList = c.querySelectorAll('[data-label]');
        let specCells = '';
        for (let s of specs) {
          let values = c.querySelectorAll('[data-label="' + s + '"]');
          if (values.length > 1) {
            specCells += '<td>' + Array.from(values).map(v => v.innerHTML).join("<br />") + '</td>'
          } else if (values.length == 1) {
            specCells += '<td>' + values[0].innerHTML + '</td>'
          } else {
            specCells += '<td></td>'
          }
        }
        let row = "<tr><td><a href=" + url + ">" + number + "</a></td>" + specCells + "</tr>";
        table.querySelector('tbody').innerHTML += row;
      }

    });
    if (this.searchInputTarget && this.searchInputTarget.value != '') {
      let rows = table.querySelectorAll('tbody tr');
      let value = this.searchInputTarget.value.trim().toLowerCase();
      if (value.length > 0) {
        this.filterRows(rows, value);
      } else {
        rows.forEach(r => r.classList.remove('hidden'));
      }
    }
    table.classList.remove('hidden');
    chart.classList.add('hidden');
    this.allResultsContainerTarget.classList.add('hidden');
    this.paginationContainerTarget.classList.add('hidden');
  }

  filterRows(rows, value) {
    rows.forEach(r => {
      let copy = '';
      for (let td of Array.from(r.querySelectorAll('td'))) {
        copy += td.innerText.toLowerCase();
      }
      if (copy.includes(value)) {
        r.classList.remove('hidden');
      } else {
        r.classList.add('hidden');
      }
    });
  }

  removeSortableChart(e) {
    let chart;
    let filterPage = this.data.get('filterpage');
    this.data.set("sortable", "false");
    this.data.set("sortableType", JSON.stringify(['notset']));
    sessionStorage.setItem(filterPage + '|sortable', "false");
    sessionStorage.setItem(filterPage + '|sortableType', JSON.stringify(['notset']));

    this.clearSortableChart();
  }

  clearSortableChart() {
    let table = this.sortableTableTarget;
    this.allResultsContainerTarget.classList.remove('hidden');
    table.classList.add('hidden');
    table.querySelector('thead tr').innerHTML = '';
    table.querySelector('tbody').innerHTML = '';
    this.paginationContainerTarget.classList.remove('hidden');
    let types = JSON.parse(this.data.get("types"));
    let adds = Array.from(this.showSortableChartButtonTargets).filter(a => types.includes(a.dataset.typename));
    adds.forEach(r => { r.classList.remove('hidden') });
    this.hideSortableChartButtonTargets.forEach(b => {
      b.classList.add('hidden');
    });
  }

  loadPartTypeContent(types) {
    let container = this.typeContainerTarget;
    for (let t of types) {
      let info = t.attributes.header_data;
      let card = '<div class="card-narrow featured col-12 hidden" data-target="filter.partTypeContent" data-typename="' + t.attributes.name + '">' + info + '</div>'
      container.innerHTML += card;
    }
  }

  updateFilterOptions(itemData, filters) {
    for (let select of this.filterSelectTargets) {
      let f = select.dataset.jsontype;
      const cardsData = [];
      if (itemData[0] && this.isJson(itemData[0][f]) && isNaN(itemData[0][f])) {
        const cardsData = itemData.map(item => JSON.parse(item[f]).reduce((acc, val) => acc.concat(val), [])).reduce((acc, val) => acc.concat(val), []).filter(Boolean).filter((v, i, a) => a.indexOf(v) === i);
        select.setAttribute("data-hasresults", JSON.stringify(cardsData));
      } else {
        const cardsData = itemData.map(item => item[f]).filter(Boolean).filter((v, i, a) => a.indexOf(v) === i);
        select.setAttribute("data-hasresults", JSON.stringify(cardsData));
      }
    }
  }

  paginate() {
    let filterPage = this.data.get('filterpage');
    let storageName = filterPage + '|page';
    let stored = JSON.parse(sessionStorage.getItem(storageName));
    if (stored != null) {
      this.data.set("page", JSON.stringify(stored));
    }
    let items = this.allResultsContainerTarget.querySelectorAll('.card:not(.hidden-card)');
    let list = Array.from(items);
    let currentPage = parseInt(this.data.get('page'));
    sessionStorage.setItem(filterPage + '|page', currentPage);
    let numberPerPage = 15;
    let numberOfPages = Math.ceil(list.length / numberPerPage);

    if (currentPage > numberOfPages) {
      currentPage = 1;
    }

    let begin = ((currentPage - 1) * numberPerPage);
    let end = begin + numberPerPage;

    let pageItems = list.slice(begin, end);

    let pageControls = this.paginationContainerTarget;

    pageControls.innerHTML = '';
    if (numberOfPages > 1) {
      pageControls.innerHTML += currentPage != 1 ? '<a class="previous_p" rel="prev" href="javascript:;" data-action="filter#changePage" data-page="' + (currentPage - 1) + '">← </a> ' : '<span class="previous_p disabled nonAvailable_choice">← </span> ';
      if (numberOfPages > 11) {
        let minPages = (currentPage - 3) < 2 ? 0 : (currentPage - 3);
        let maxPages = (currentPage + 2) < numberOfPages ? (currentPage + 2) : numberOfPages;
        if (currentPage > 3) {
          pageControls.innerHTML += 1 == currentPage ? '<em class="current b">' + 1 + '</em> ' : '<a href="javascript:;" data-action="filter#changePage" data-page="' + 1 + '">' + 1 + '</a> ';
          pageControls.innerHTML += " ... ";
        }
        for (let i = minPages; i < maxPages; i++) {
          let pageNumber = i + 1;
          pageControls.innerHTML += pageNumber == currentPage ? '<em class="current b">' + pageNumber + '</em> ' : '<a href="javascript:;" data-action="filter#changePage" data-page="' + pageNumber + '">' + pageNumber + '</a> ';
        }
        if (currentPage < (numberOfPages - 2)) {
          pageControls.innerHTML += " ... ";
          pageControls.innerHTML += numberOfPages == currentPage ? '<em class="current b">' + numberOfPages + '</em> ' : '<a href="javascript:;" data-action="filter#changePage" data-page="' + numberOfPages + '">' + numberOfPages + '</a> ';
        }
      } else {
        for (let i = 0; i < numberOfPages; i++) {
          let pageNumber = i + 1;
          pageControls.innerHTML += pageNumber == currentPage ? '<em class="current b">' + pageNumber + '</em> ' : '<a href="javascript:;" data-action="filter#changePage" data-page="' + pageNumber + '">' + pageNumber + '</a> ';
        }
      }
      pageControls.innerHTML += currentPage != numberOfPages ? '<a class="next_p" rel="next" href="javascript:;" data-action="filter#changePage" data-page="' + (currentPage + 1) + '"> →</a> ' : '<span class="next_p disabled nonAvailable_choice"> →</span> ';
      for (let item of list) {
        if (pageItems.includes(item)) {
          item.classList.remove('hidden');
        } else {
          item.classList.add('hidden');
        }
      }
    }
  }

  changePage(e) {
    let filterPage = this.data.get('filterpage');
    let page = e.target.dataset.page;
    this.data.set('page', page);
    sessionStorage.setItem(filterPage + '|page', page);
    this.paginate();
    let top = document.getElementById("results");
    top.scrollIntoView();
  }

  resetPage() {
    let filterPage = this.data.get('filterpage');
    this.data.set('page', 1);
    sessionStorage.setItem(filterPage + '|page', 1);
  }

  prepPartTypes(ids, typeData, filterPage) {
    console.log("prepping part types");
    let types = typeData.data.filter(t => ids.includes(t.attributes.name));
    let typesWithHeaderContent = types.filter(t => t.attributes.header_data && t.attributes.header_data.length != 0);
    if (typesWithHeaderContent.length != 0) {
      this.loadPartTypeContent(typesWithHeaderContent);
    }
    let typesWithSortableContent = types.filter(t => t.attributes.has_chart == true);
    if (typesWithSortableContent.length != 0) {
      this.loadSortableButtons(typesWithSortableContent);
    }

    let storageSortableName = filterPage + '|sortable';
    let storedSortable = JSON.parse(sessionStorage.getItem(storageSortableName));
    if (storedSortable != null) {
      this.data.set("sortable", storedSortable);
    }

    let storageSortableTypeName = filterPage + '|sortableType';
    let storedSortableType = JSON.parse(sessionStorage.getItem(storageSortableTypeName));

    if (storedSortableType != null) {
      this.data.set("sortableType", storedSortableType);
    }
  }

  searchCards(e) {
    let items = Array.from(this.allResultsContainerTarget.querySelectorAll('.card:not(.hidden-card)'));
    let value = e.target.value.trim().toLowerCase();
    if (value.length > 0) {
      this.paginationContainerTarget.classList.add('hidden');
      for (let item of items) {
        let copy = '';
        copy += item.innerText.toLowerCase();
        if (copy.includes(value)) {
          item.classList.remove('hidden');
        } else {
          item.classList.add('hidden');
        }
      }
    } else {
      for (let item of items) {
        item.classList.remove('hidden');
      }
      this.paginate();
      this.paginationContainerTarget.classList.remove('hidden');
    }
  }


  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

}