export default function timeIsMoney() {
	if ($('#transmission_options_oem').is(':checked')) {
		$('.oem').removeClass('hidden');
	}
	else {
		$('.oem').addClass('hidden');
	}
	if ($('#transmission_options_reman').is(':checked')) {
		$('.reman').removeClass('hidden');
	}
	else {
		$('.reman').addClass('hidden');
	}
	if ($('#transmission_options_rebuild').is(':checked')) {
		$('.rebuild, .rebuild-tool').removeClass('hidden');
	}
	else {
		$('.rebuild, .rebuild-tool').addClass('hidden');
	}
	if ($('#transmission_options_salvaged').is(':checked')) {
		$('.salvaged').removeClass('hidden');
	}
	else {
		$('.salvaged').addClass('hidden');
	}
	
	var trans_per_day = $('[name="trans_per_month"]').val() / 30;
	var net_profit_per_trans = $('[name="trans_per_month"]').val() * $('[name="net_profit_per_trans"]').val();
	var production_value_per_hour = $('[name="revenue_per_month"]').val() / ($('[name="hours_per_person_per_month"]').val() * $('[name="no_employees"]').val());
	var tool_cost_per_job = $('[name="tool_cost"]').val() / $('[name="tool_life"]').val();
	var vb_parts_cost = +tool_cost_per_job + +$('#valve_cost').val();

	var ts_options = ["oem", "reman", "rebuild_vb", "rebuild_solenoids", "rebuild_hard", "salvaged"];
	var rate_options = ["procurement", "inventory_handling", "disassembly", "rebuilding", "test"];
	
	//do all calculations for all transmission options
	for (var i = 0; i < 6; i++) {
		var t_o = ts_options[i];

		//costs & labor for units (any or just good)
		window[t_o+"_labor_any_unit"] = 0;
		window[t_o+"_labor_good_unit"] = 0;
		for (var r = 0; r < 5; r++) {
    	var r_o = rate_options[r];
    	window[t_o+"_"+r_o+"_actual_hours"] = 0
			var rate = $("#" + r_o + "_rate").val();
			var part_yield = $("#" + t_o + "_yield").val();
			var yield_percentage = part_yield / 100;
			var hours = $("#" + t_o + "_" + r_o).val();
			window[t_o+"_"+r_o+"_actual_hours"] += +hours;
			window[t_o+"_"+r_o+"_actual_hours"] += (((1-yield_percentage)*100)/(yield_percentage*100))*hours;
			//console.log(t_o+"_"+r_o+" actual hours: "+window[t_o+"_"+r_o+"_actual_hours"])
			window[t_o+"_"+r_o+"_cost"] = rate * window[t_o+"_"+r_o+"_actual_hours"];
			window[t_o+"_labor_any_unit"] += +hours;
			//console.log(t_o+"_"+r_o+" "+window[t_o+"_labor_any_unit"]);
			window[t_o+"_labor_good_unit"] += +window[t_o+"_"+r_o+"_actual_hours"]
		}

		//loss due to leadtime
		window[t_o+"_loss_due_to_leadtime"] = $("#"+ t_o + "_lead_time_loss").val() * $("#net_profit_per_trans").val();

		
		//hard cost
		window[t_o+"_hard_cost"] = 0;
		window[t_o+"_hard_cost"] += +$("#"+t_o+"_parts_cost").val();
		window[t_o+"_hard_cost"] += +$("#"+t_o+"_incoming_frieght").val();
		window[t_o+"_hard_cost"] += +window[t_o+"_loss_due_to_leadtime"];
		window[t_o+"_costs"] = 0;
		for (var r = 0; r < 5; r++) {
			var r_o = rate_options[r];
			window[t_o+"_costs"] += +window[t_o+"_"+r_o+"_cost"]
		}
		window[t_o+"_hard_cost"] += +window[t_o+"_costs"];
		window[t_o+"_hard_cost"] -= $("#"+t_o+"_add_value").val();


		//loss due to comeback
		var comeback_rate = $("#"+ t_o + "_component_comeback").val() / 100;
		window[t_o+"_loss_due_to_comeback"] = window[t_o+"_hard_cost"] * comeback_rate;


		//extra labor
		window[t_o+"_extra_labor"] = window[t_o+"_labor_good_unit"] - window[t_o+"_labor_any_unit"];
		console.log(t_o+" _extra_labor: "+window[t_o+"_extra_labor"]);

		//lost production hours
		window[t_o+"_lost_production"] = window[t_o+"_extra_labor"] * production_value_per_hour;

		//total costs
		window[t_o+"_total_cost"] = 0;
		window[t_o+"_total_cost"] += +window[t_o+"_hard_cost"];
		window[t_o+"_total_cost"] += +window[t_o+"_loss_due_to_comeback"];
		window[t_o+"_total_cost"] += +window[t_o+"_lost_production"];


		//net profits
		window[t_o+"_net_profit"] = 0;
		window[t_o+"_net_profit"] += +$("#"+t_o+"_sell_price").val();
		window[t_o+"_net_profit"] -= window[t_o+"_total_cost"];
		//console.log(t_o+" net profit: "+window[t_o+"_net_profit"]);
	}
	var rebuild_comebacks = $("#rebuild_hard_component_comeback").val();
	$("#rebuild_vb_component_comeback").val(rebuild_comebacks);
	$("#rebuild_solenoids_component_comeback").val(rebuild_comebacks);
	var rebuild_cost = 0;
	rebuild_cost += +window["rebuild_vb_total_cost"];
	rebuild_cost += +window["rebuild_solenoids_total_cost"];
	rebuild_cost += +window["rebuild_hard_total_cost"];
	var rebuild_net_profit = $("#rebuild_sell_price").val() - rebuild_cost;
	$("#rebuild_vb_parts_cost").val(vb_parts_cost.toFixed(2));
	$("#oem_net_profit span").text(oem_net_profit.toFixed(2));
	$("#reman_net_profit span").text(reman_net_profit.toFixed(2));
	$("#rebuild_net_profit span").text(rebuild_net_profit.toFixed(2));
	$("#salvaged_net_profit span").text(salvaged_net_profit.toFixed(2));
	$("#oem_total_cost span").text(oem_total_cost.toFixed(2));
	$("#reman_total_cost span").text(reman_total_cost.toFixed(2));
	$("#rebuild_total_cost span").text(rebuild_cost.toFixed(2));
	$("#salvaged_total_cost span").text(salvaged_total_cost.toFixed(2));
	$(".rebuild_vb_parts_cost").val(vb_parts_cost.toFixed(2));
	$(".oem_net_profit").text(oem_net_profit.toFixed(2));
	$(".reman_net_profit").text(reman_net_profit.toFixed(2));
	$(".rebuild_net_profit").text(rebuild_net_profit.toFixed(2));
	$(".salvaged_net_profit").text(salvaged_net_profit.toFixed(2));
	$('input').each(function() {
		var fieldId = $(this).attr('id');
		var contents = $(this).val();
		$("."+fieldId).text(contents);
	});
		
};