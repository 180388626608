import "core-js/stable";
import "regenerator-runtime/runtime";

import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["svg", "svgContainer", "modal", "emptyModal", "card"]

	activateExplodedView() {
		var svgDoc = this.svgTarget.contentDocument;
		var element = svgDoc.getElementById('allparts');
		var elements = element.getElementsByTagName("g");
		var part_list = this.svgContainerTarget.dataset.ids.split("\"");
		var modal = this.modalTarget;
		var emptyModal = this.emptyModalTarget;
		var cards = this.cardTargets;
		var ua = window.navigator.userAgent;
		var msie = ua.indexOf("MSIE ");

		for (let e of Array.from(elements)) {
			var id = String(e.id);
			var idSub = id.match(/^(\d+)_1_$/);
			if (idSub) {
				id = idSub[1];
			}
			if (part_list.indexOf(id) != -1) {
				if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
					var classes = e.className.baseVal.split(" ");
					e.setAttribute('class', classes + ' ' + "part");
				} else {
					e.classList.add("part");
				}
				e.onclick = function (g) {
					var id = e.id;
					var idSub = id.match(/^(\d+)_1_$/);
					if (idSub) {
						id = idSub[1];
					}
					for (let c of cards) {
						if (c.dataset.ref == id) {
							var foo = c.cloneNode(true);
							emptyModal.appendChild(foo);
						}
					};
					modal.classList.remove("hidden");
					return false;
				};
			}
		}
	}

	hideModal() {
		this.modalTarget.classList.add("hidden");
		this.emptyModalTarget.innerHTML = '';
	}
}