import "core-js/stable";
import "regenerator-runtime/runtime";

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "modal" ]

  display() {
    this.modalTarget.classList.remove("hidden");
  }
  
  hide() {
  	this.modalTarget.classList.add("hidden");
  }
}