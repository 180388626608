import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "pistonDia", "innerDia", "outerDia", "resultBox", "requiredPressure", "minPressure", "maxPressure" ]

  calculate() {
  	var piston = this.pistonDiaTarget.value || 0;
  	var inner = this.innerDiaTarget.value || 0;
  	var outer = this.outerDiaTarget.value || 0;

  	var piston_area = (piston / 2) * (piston / 2) * Math.PI;
    var outer_area = (outer/2) * (outer / 2) * Math.PI;
    var inner_area = (inner/2) * (inner / 2) * Math.PI;
    var ring_area = outer_area - inner_area;
    var min_force = 200 * ring_area
    var max_force = 400 * ring_area
    var min_required = Math.round(min_force / piston_area)
    var max_required = Math.round(max_force / piston_area)
    var required = Math.round((min_required + max_required) / 2)

    if (isFinite(required)) {
    	this.resultBoxTarget.classList.remove("offscreen");
    	this.requiredPressureTarget.textContent = required
    	this.minPressureTarget.textContent = min_required
    	this.maxPressureTarget.textContent = max_required
    }
  }
}