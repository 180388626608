export default function responsiveTables() {
  $('.tech-article table').each(function() {
    var column_count = 0;
    $(this).find('tr:first').children('td, th').each(function(){
      column_count += this.colSpan;
    });
    $(this).find('tr').each(function() {
      var child_count = $(this).children('td, th').length
      if (child_count == column_count) {
        $(this).addClass("headerlabels");
        $(this).children('td, th').each(function(){
          var index = 0;
          $(this).prevAll("td, th").each(function() {
            index += this.colSpan;
          });
          $(this).attr("data-header_index", index);
        });
        return false;
      }
    });
    $(this).find('td, th').not('[data-header_index]').each(function(){
      var index = 0;
      $(this).prevAll("td, th").each(function() {
        index += this.colSpan;
      });
      var new_label = $(this).closest('table').find("[data-header_index="+index+"]").text();
      $(this).attr("data-label", new_label);
    });
  });
}