import "core-js/stable";
import "regenerator-runtime/runtime";

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "makeSelect", "unitSelect", "unitOption", "hiddenUnitSelect" ]


  clearSelects(e) { 
    e.preventDefault();
    this.makeSelectTarget.selectedIndex = -1;
    this.unitSelectTarget.selectedIndex = -1;
    window.location = this.nearestAncestorHref(e.target);
  }

  selectMake() {
  	var transMake = this.makeSelectTarget.selectedOptions[0].value;
    if (transMake == "") {
      var transSecondSelectName = this.unitSelectTarget.options[0].text
      //console.log("Select Make")
      for (let o of this.unitOptionTargets) {
        var parent = o.parentNode;
        if (parent.dataset.target == "make-select.hiddenUnitSelect") {
          var foo = o.cloneNode(true);
          this.unitSelectTarget.appendChild(foo);
          this.hiddenUnitSelectTarget.removeChild(o);}
      }
      var optionToRemove = this.unitSelectTarget.querySelector('option[value=""]')
      if (optionToRemove) {
        optionToRemove.remove();
        //console.log("option removed")
      }
      this.sortSelect(this.unitSelectTarget);
      var selectGuide = new Option(transSecondSelectName,'')
      this.unitSelectTarget.insertBefore(selectGuide, this.unitSelectTarget.firstChild);
      this.unitSelectTarget.selectedIndex = 0;
      this.unitSelectTarget.focus();
      //console.log(this.unitSelectTarget)
    }
    else {
      for (let o of this.unitOptionTargets) {
        var parent = o.parentNode;
        if (o.dataset.make != transMake) {
          if (parent.dataset.target == "make-select.unitSelect") {
            var foo = o.cloneNode(true);
            this.hiddenUnitSelectTarget.appendChild(foo);
            this.unitSelectTarget.removeChild(o);
          }
        } else {
          if (parent.dataset.target == "make-select.hiddenUnitSelect") {
            var foo = o.cloneNode(true);
            this.unitSelectTarget.appendChild(foo);
            this.hiddenUnitSelectTarget.removeChild(o);
          }
        }
      }
      this.unitSelectTarget.focus();

    }
  }

  selectChange(e) {
    const location = e.target.value;
    if (this.hasMakeSelectTarget) {
      this.makeSelectTarget.value = '';
    }
    e.target.value = '';
    console.log(e.target.value);
    setTimeout(() => {  window.location.href = location; }, 700);
  }

  findKit(e) {
    const kitContainer = document.getElementById('allkits');
    //console.log(e.target.value);
    kitContainer.querySelectorAll('.shiftkit').forEach(sk => {
      //console.log(sk.dataset.unit.split('|'));
      sk.dataset.unit.split('|').includes(e.target.value) ? sk.classList.remove("hidden") : sk.classList.add("hidden")
    });
  }

  nearestAncestorHref(node){
    while(node && !node.href) node=node.parentNode
    return node && node.href
  }

  sortSelect(selElem) {
    var tmpAry = new Array();
    for (var i=0;i<selElem.options.length;i++) {
        tmpAry[i] = new Array();

          tmpAry[i][0] = selElem.options[i].text;
          tmpAry[i][1] = selElem.options[i].value;
          tmpAry[i][2] = selElem.options[i].dataset.make;
          tmpAry[i][3] = selElem.options[i].dataset.target;

    }
    tmpAry.sort();
    while (selElem.options.length > 0) {
        selElem.options[0] = null;
    }
    for (var i=0;i<tmpAry.length;i++) {
        var op = new Option(tmpAry[i][0], tmpAry[i][1]);

          op.dataset.make = tmpAry[i][2];
          op.dataset.target = tmpAry[i][3];

        selElem.options[i] = op;
    }
    return;
  }
}