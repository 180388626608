import "core-js/stable";
import "regenerator-runtime/runtime";
import 'element-closest-polyfill';
import 'whatwg-fetch';
import 'datalist-polyfill';

import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["filterSelect", "filters", "noResults", "getLinkButton", "datalistInput", "clearFiltersButton", "generateUrl", "cardContainer", "linkContainer", "urlParams", "initial", "allResultsContainer", "sortableTable", "sortableContainer", "showSortableChartButton", "hideSortableChartButton", "specValue", "partTypeContent", "typeContainer"]

  initialize() {
    if (!this.data.has("initialized")) {
      this.loadFilters();
    }
    this.data.set('initialized', true);
    
  }

  loadFilters() {
    let filterList = JSON.parse(this.data.get('list'));
    let filterPage = this.data.get('filterpage');
    const uri = window.location.toString();
    if (uri.indexOf("?") > 0) {
      const clean_uri = uri.substring(0, uri.indexOf("?"));
      let eArray = [];
      for (let f of filterList) {
        if (f != "hp" && f!= "fp" && f!= "zp") {
          sessionStorage.setItem(filterPage + "|" + f, JSON.stringify(eArray));
        }
      }
      let hpCheckbox = document.getElementById('hp_filter');
      if (hpCheckbox && hpCheckbox.checked == false) {
        sessionStorage.setItem(filterPage + "|hp", "false");
      } else {
        sessionStorage.setItem(filterPage + "|hp", "true");
      }
      let fpCheckbox = document.getElementById('fp_filter');
      if (fpCheckbox && fpCheckbox.checked == false) {
        sessionStorage.setItem(filterPage + "|fp", "false");
      } else {
        sessionStorage.setItem(filterPage + "|fp", "true");
      }
      let zpCheckbox = document.getElementById('zp_filter');
      if (zpCheckbox && zpCheckbox.checked == false) {
        sessionStorage.setItem(filterPage + "|zp", "false");
      } else {
        sessionStorage.setItem(filterPage + "|zp", "true");
      }
      window.history.replaceState({}, document.title, clean_uri);
    }

    let items = Array.from(this.allResultsContainerTarget.querySelectorAll('.card:not(.hidden-card)'));

    for (let f of filterList) {
      if (f != "hp" && f != "fp" && f != "zp") {
        let select = this.filterSelectTargets.filter(r => r.dataset.jsontype == f)[0];
        let stored = this.setSelections(f, select, filterPage);
        let ids = items.map(item => JSON.parse(item.dataset[f]).reduce((acc, val) => acc.concat(val), [])).reduce((acc, val) => acc.concat(val), []).filter(Boolean).filter((v, i, a) => a.indexOf(v) === i);
        this.getOptions(select, ids, stored);
      } else if (f == "hp") {
        let hpInput = document.getElementById("hp_filter");
        let storageHpName = filterPage+'|hp';
        let storedHp = JSON.parse(sessionStorage.getItem(storageHpName));
        if (storedHp != null) {
          this.data.set("hp", storedHp.toString());
          if (storedHp == true) {
            hpInput.checked = true;
            hpInput.closest('label').classList.add('hp-filter-active');
          } else {
            hpInput.checked = false;
            hpInput.closest('label').classList.remove('hp-filter-active');
          }
        }
      }
      else if (f == "fp") {
        let fpInput = document.getElementById("fp_filter");
        let storageFpName = filterPage+'|fp';
        let storedFp = JSON.parse(sessionStorage.getItem(storageFpName));
        if (storedFp != null) {
          this.data.set("fp", storedFp.toString());
          if (storedFp == true) {
            fpInput.checked = true;
            fpInput.closest('label').classList.add('hp-filter-active');
          } else {
            fpInput.checked = false;
            fpInput.closest('label').classList.remove('hp-filter-active');
          }
        }
      } else if (f == "zp") {
        let zpInput = document.getElementById("zp_filter");
        let storageZpName = filterPage+'|zp';
        let storedZp = JSON.parse(sessionStorage.getItem(storageZpName));
        if (storedZp != null) {
          this.data.set("zp", storedZp.toString());
          if (storedZp == true) {
            zpInput.checked = true;
            zpInput.closest('label').classList.add('zp-filter-active');
          } else {
            zpInput.checked = false;
            zpInput.closest('label').classList.remove('zp-filter-active');
          }
        }
      }
      
    }

    this.initializeDatalists(this.datalistInputTargets);

    if (document.body.scrollWidth >= 720) {
      this.filtersTarget.checked = true;
    }

    this.filterCards();
    
  }

  setSelections(filter, select, filterPage) {
    let storageName = filterPage+'|' +filter;
    let params = select.dataset.paramdata;
    if (params) {
      let pArray = [];
      if (params.includes('|')) {
        for (let p of params.split('|')) {
          if (filter == 'types' || filter == 'rtype' || filter == 'author' || filter == 'symptoms') {
            pArray.push(decodeURI(p));
          } else {
            pArray.push(parseInt(p));
          }
        }
      } else {
        if (filter == 'types' || filter == 'rtype' || filter == 'author' || filter == 'symptoms') {
          pArray.push(decodeURI(params));
        } else {
          pArray.push(parseInt(params));
        }
      }
      sessionStorage.setItem(storageName, JSON.stringify(pArray));
    }
    let stored = JSON.parse(sessionStorage.getItem(storageName));
    if (stored != null) {
      this.data.set(filter, JSON.stringify(stored));
    }
    return stored
  }

  initializeDatalists(lists) {
    lists.forEach(dl => {
      const list = dl.parentNode.querySelector("#"+dl.getAttribute("list"));
      let select = dl.parentNode.querySelector("#"+dl.dataset.datalistfor);
      if (dl.dataset.datalisttype == "multiple" && select.value != '') {
        const label = document.querySelector("label[for="+dl.dataset.datalistfor+"]");
        select.querySelectorAll('option:checked').forEach(so => {
          dl.insertAdjacentHTML('beforebegin',"<div class='datalist-selections' data-label='"+so.innerHTML+"' data-dlinput='"+dl.name+"' data-list='#"+dl.getAttribute("list")+"' data-select='#"+dl.dataset.datalistfor+"' data-value='"+so.value+"' data-action='click->ufilter#removeDatalistOption'>"+so.innerHTML+" &#9447;</div>");
        });
      } else {
        const sv = select.querySelector("option[value='"+select.value+"']");
        if (sv) {
          dl.value = sv.innerHTML;
        }
      }
    })
  }

  getOptions(select, ids, stored) {
    const allOptions = Array.from(select.querySelectorAll('option'));
    let selectedOptions;
    if (stored != null) {
      let selectedOptions = allOptions.filter(o => stored.includes(isNaN(o.value) ? o.value : parseFloat(o.value)))
      for (let o of selectedOptions) {
        o.selected = 'selected';
      }
    }
    select.setAttribute('data-hasResults', JSON.stringify(ids))
  }

  changeBooleanFilter(e) {
    this.updateFilter(e.target);
  }

  changeFilters(e) {
    let dl = e.target;
    const list = document.querySelector("#"+dl.getAttribute("list"));
    const selectedOption = list.querySelector("option[value='"+dl.value+"']");
    const filterInput = dl.parentNode.querySelector("#"+dl.dataset.datalistfor);

    if (selectedOption) {
      filterInput.querySelector("option[value='"+selectedOption.dataset.value+"']").selected = true;
    }

    if (dl.dataset.datalisttype == "multiple" && dl.value != '') {
      const label = dl.parentNode.querySelector("label[for="+this.data.get('formtype')+"_" + dl.dataset.datalistfor+"]");
      dl.insertAdjacentHTML('beforebegin', "<div class='datalist-selections' data-label='"+dl.value+"' data-dlinput='"+dl.name+"' data-list='#"+dl.getAttribute("list")+"' data-select='#"+dl.dataset.datalistfor+"' data-value='"+selectedOption.dataset.value+"' data-action='click->ufilter#removeDatalistOption'>"+dl.value+" &#9447;</div>")
      selectedOption.disabled = true;
      dl.value = '';
    }

    this.updateFilter(filterInput);
  }

  updateFilter(filterInput) {

    let filterName = filterInput.dataset.jsontype;
    let filterPage = this.data.get('filterpage');
    let filterValues = [];
    if (filterInput.nodeName == 'SELECT') {
      for (let o of filterInput.selectedOptions){
        let value = isNaN(o.value) ? o.value : parseFloat(o.value); 
        filterValues.push(value)
      };
    } else {
      let value = isNaN(filterInput.value) ? filterInput.value : parseFloat(filterInput.value) ; 
      filterValues.push(value)
    }
    if (filterName == "hp") {
      let hpInput = document.getElementById("hp_filter");
      if (hpInput.checked == true) {
        this.data.set(filterName, "true");
        sessionStorage.setItem(filterPage+'|'+filterName, "true");
        hpInput.closest('label').classList.add('hp-filter-active');
      } else {
        this.data.set(filterName, "false");
        sessionStorage.setItem(filterPage+'|'+filterName, "false");
        hpInput.closest('label').classList.remove('hp-filter-active');
      }
    } else if (filterName == "fp") {
      let fpInput = document.getElementById("fp_filter");
      if (fpInput.checked == true) {
        this.data.set(filterName, "true");
        sessionStorage.setItem(filterPage+'|'+filterName, "true");
        fpInput.closest('label').classList.add('hp-filter-active');
      } else {
        this.data.set(filterName, "false");
        sessionStorage.setItem(filterPage+'|'+filterName, "false");
        fpInput.closest('label').classList.remove('hp-filter-active');
      }

    } else if (filterName == "zp") {
      let zpInput = document.getElementById("zp_filter");
      if (zpInput.checked == true) {
        this.data.set(filterName, "true");
        sessionStorage.setItem(filterPage+'|'+filterName, "true");
        zpInput.closest('label').classList.add('zp-filter-active');
      } else {
        this.data.set(filterName, "false");
        sessionStorage.setItem(filterPage+'|'+filterName, "false");
        zpInput.closest('label').classList.remove('zp-filter-active');
      }

    } else {
      this.data.set(filterName, JSON.stringify(filterValues));
      sessionStorage.setItem(filterPage+'|'+filterName, JSON.stringify(filterValues));
    }

    this.filterCards();
  }

  removeDatalistOption(e) {
    const so = e.target;
    const list = document.querySelector(so.dataset.list);
    const select = document.querySelector(so.dataset.select);

    const dlSelectedOption = list.querySelector("option[value='"+so.dataset.label+"']");
    const sSelectedOption = select.querySelector("option[value='"+so.dataset.value+"']");

    sSelectedOption.selected = false;
    dlSelectedOption.disabled = false;
    so.parentNode.removeChild(so);
    this.updateFilter(select)
  }

  clearAllFilters() {
    this.datalistInputTargets.forEach(filterInput => {
      const select = filterInput.parentNode.querySelector("#"+filterInput.dataset.datalistfor)
      filterInput.value = '';
      select.value = '';
      this.updateFilter(select);
    });
    const hpInput = document.getElementById("hp_filter");
    if (hpInput) {
      hpInput.checked = false;
      this.updateFilter(hpInput);
    }
    const fpInput = document.getElementById("fp_filter");
    if (fpInput) {
      fpInput.checked = false;
      this.updateFilter(fpInput);
    }
    const zpInput = document.getElementById("zp_filter");
    if (zpInput) {
      zpInput.checked = false;
      this.updateFilter(zpInput);
    }
    document.querySelectorAll(".datalist-selections").forEach(d => d.parentNode.removeChild(d))
  }

  generateUrl(e) {
    let filterList = JSON.parse(this.data.get('list'));
    let url = window.location.toString() + "?";
    for (let f of filterList) {
      let fi = JSON.parse(this.data.get(f));
      if (f == "hp") {
        if (fi == true) {
          url += "&high_performance=true";
        }
      } else if (f == "fp") {
        if (fi == true) {
          url += "&featured_unit=true";
        }
      } else if (f == "zp") {
        if (fi == true) {
          url += "&zp=true";
        }
      } else {
        for (let v of fi) {
          url += "&"+f+"[]="+encodeURI(v);
        }
      }
    }
    let copyText = this.linkContainerTarget;
    copyText.value = url;
    copyText.select(); 
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    alert("Copied link");
  }

  filterCards() {
    let filterList = JSON.parse(this.data.get('list'));
    let cards = Array.from(this.allResultsContainerTarget.querySelectorAll('.card'));

    let itemData = [];

    for (let card of cards) {
      let results = [];
      for (let f of filterList) {
        let fi = JSON.parse(this.data.get(f));
        if (f == "hp" || f == "fp" || f == "zp") {
          if (fi == true) {
            let values = [];
            values.push(card.dataset[f]);
            if (values.includes(this.data.get(f))) {
              results.push(true)
            } else {
              results.push(false)
            }
          } else {
            results.push(true)
          }
        } else {
          if (fi.length != 0) {

            let values = [];
            if (this.isJson(card.dataset[f]) && typeof JSON.parse(card.dataset[f]) != "boolean") {
              values = JSON.parse(card.dataset[f]);
            } else {
              if (card.dataset[f].includes(',')) {
                for (let v of card.dataset[f].split(',')) (values.push(v))
              } else {
                values.push(card.dataset[f]);
              }
            }
            
            let intersection = values.filter(v => fi.includes(v));
            
            
            if (intersection.length != 0) {
              results.push(true);
            } else {
              results.push(false);
            }
          }
        }
      }
      if (results.includes(false)) {
        card.classList.add('hidden-card');
      } else {
        card.classList.remove('hidden-card');
        card.classList.remove('hidden');
        itemData.push(Object.assign({}, card.dataset));
      }
    }
    this.updateFilterOptions(itemData, filterList);
    this.conatainerVisibility();

    if (cards.length != itemData.length) {
      this.getLinkButtonTarget.classList.remove('hidden');
      this.clearFiltersButtonTarget.classList.remove('hidden');
      if (itemData.length == 0) {
        this.noResultsTarget.classList.remove('hidden');
      } else {
        this.noResultsTarget.classList.add('hidden');
      }
    } else {
      this.getLinkButtonTarget.classList.add('hidden');
      this.clearFiltersButtonTarget.classList.add('hidden');
    }
    
  }

  showPartTypeContent(names) {
    this.partTypeContentTargets.forEach(partType => {
      let type = partType.dataset.typename;
      
      if (names.includes(type)) {
        partType.classList.remove('hidden');
      } else {
        partType.classList.add('hidden');
      }
    });
  }

  hidePartTypeContent(names) {
    this.partTypeContentTargets.forEach(partType => {
      partType.classList.add('hidden');
    });
  }

  loadPartTypeContent(types) {
    let container = this.typeContainerTarget;
    for (let t of types) {
      let info = t.attributes.header_data;
      let card = '<div class="card-narrow featured col-12 hidden" data-target="filter.partTypeContent" data-typename="'+t.attributes.name+'">'+info+'</div>'
      container.innerHTML += card;
    } 
  }

  updateFilterOptions(itemData, filters) {
    for (let select of this.filterSelectTargets) {
      let f = select.dataset.jsontype;
      const cardsData = [];
      if (itemData[0] && this.isJson(itemData[0][f]) && isNaN(itemData[0][f])) {
        const cardsData = itemData.map(item => JSON.parse(item[f]).reduce((acc, val) => acc.concat(val), [])).reduce((acc, val) => acc.concat(val), []).filter(Boolean).filter((v, i, a) => a.indexOf(v) === i);
        select.setAttribute("data-hasresults", JSON.stringify(cardsData));
      } else {
        const cardsData = itemData.map(item => item[f]).filter(Boolean).filter((v, i, a) => a.indexOf(v) === i);
        select.setAttribute("data-hasresults", JSON.stringify(cardsData));
      }
    }
  }

  conatainerVisibility() {
    let containers = Array.from(this.cardContainerTargets);
    for (let c of containers) {
      if (c.querySelector('.card:not(.hidden-card):not(.hidden)') !== null) {
        c.classList.remove('hidden');
      } else {
        c.classList.add('hidden');
      } 
    }
  }

  prepPartTypes(ids, typeData, filterPage) {
    let types = typeData.data.filter(t => ids.includes(t.attributes.name));
    let typesWithHeaderContent = types.filter(t => t.attributes.header_data && t.attributes.header_data.length != 0);
    if (typesWithHeaderContent.length != 0) {
      this.loadPartTypeContent(typesWithHeaderContent);
    }
  }

  searchCards(e) {
    let items = Array.from(this.allResultsContainerTarget.querySelectorAll('.card:not(.hidden-card)'));
    let value = e.target.value.trim().toLowerCase();
    if (value.length > 0) {
      for (let item of items) {
        let copy = '';
        copy += item.innerText.toLowerCase();
        if (copy.includes(value)) {
          item.classList.remove('hidden');
        } else {
          item.classList.add('hidden');
        }
      }
    } else {
      for (let item of items) {
        item.classList.remove('hidden');
      }
    }
  }

  triggerFilter(e) {
    const fval = e.target.innerText;
    const filterName = e.target.dataset.filtertrigger;
    const select = document.getElementById(filterName);
    const inputName = filterName + "DataList";
    const input = select.parentNode.querySelector("input[name='"+inputName+"']")
    input.value = fval;
    var event = new Event('input', { 'bubbles': true, 'cancelable': true });
    
    input.dispatchEvent(event);
  }


  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

}