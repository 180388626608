import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "boltDia", "distance", "resultBox", "result", "resultB" ]

  initialize() {
    console.log("initialize");
  }

  calculate() {
  	const b = parseFloat(this.boltDiaTarget.value) || 0;
    const d = parseFloat(this.distanceTarget.value) || 0;

    const sine = (Math.sqrt(3))/2;

    let boltCircleDia = ((d+b)/sine).toFixed(3);
    let works = "DOES"
    if (isFinite(boltCircleDia)) {
      if (104.5 <= boltCircleDia && boltCircleDia <= 105.99) {
        this.resultBoxTarget.style.background = "green"
      } else {
        this.resultBoxTarget.style.background = "red"
        works = "DOES NOT";
      }
    	this.resultTarget.textContent = boltCircleDia;
      this.resultBTarget.textContent = works;
      this.resultBoxTarget.classList.remove("offscreen");
    }
  }
}