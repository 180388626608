import "core-js/stable";
import "regenerator-runtime/runtime";

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "submitButton", "submitLabel", "requestedAll" ]
  
  connect() {
    // update your element here
    this.enableSubmit();
  }

  enableSubmit() {
  	var checked = this.checkboxTargets.find(target => target.checked);
    var checkedCount = this.checkboxTargets.length - this.checkboxTargets.filter(box => box.checked).length;
    if (checked) {
    	this.submitButtonTarget.disabled = false;
    	this.submitLabelTarget.classList.add("hidden");
    }
    else {
    	this.submitButtonTarget.disabled = true;
    	this.submitLabelTarget.classList.remove("hidden");
    }
    this.requestedAllTarget.checked = (checkedCount === 0) ? true :false;
  }
}