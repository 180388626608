import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "ac"]

  initialize() {
    this.check()
  }

  check() {
  	if (document.body.scrollWidth >= 720) {
      this.acTarget.checked = true;
    }
  }
 }