import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "noticeContainer", "noticeDismiss"]

  initialize() {
    let notice = this.getWithExpiry(this.data.get("noticename"));
    if (notice != true) {
      this.noticeContainerTarget.classList.remove('hidden');
    } else {
      let doc = document.getElementById("container");
      doc.style.marginTop='1em';
    }

  }

  dismissNotice() {
    this.noticeContainerTarget.classList.add('hidden');
    let doc = document.getElementById("container");
    doc.style.marginTop='1em';
    this.setWithExpiry(this.data.get("noticename"), "true", 86400000);
  }

  setWithExpiry(key, value, ttl) {
    const now = new Date()

    const item = {
      value: value,
      expiry: now.getTime() + ttl
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)

    if (!itemStr) {
      return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key)
      return null
    }
    return true;
  } 
}