import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mcontent", "smallscreen", "desktop"]

  initialize() {
    this.check()
  }

  check() {
  	let mc = this.mcontentTarget;
  	if (document.body.scrollWidth <= 1200) {
      this.smallscreenTarget.appendChild(mc);
    } else {
    	this.desktopTarget.appendChild(mc)
    }
  }
 }