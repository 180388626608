import "core-js/stable";
import "regenerator-runtime/runtime";


import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['searchInput', 'sortableTable', 'columnSelect', 'columnInput']

  initialize() {
    if (!this.data.has("initialized")) {
      const ths = Array.from(this.sortableTableTarget.querySelectorAll('thead th'));
      for (const [i, th] of ths.entries()) {
        th.dataset.sort = 'desc';
        th.dataset.index = i;
        if(!th.classList.contains('nosort')) {
          th.dataset.action = 'click->table#sortTable';
        }
        const txt = th.innerText || th.textContent;
        th.dataset.label = txt;
        if (!th.innerHTML.includes("sort_indicator")) {
          if(!th.classList.contains('nosort')) {
            th.innerHTML += "<span class='sort_indicator'>&varr;</span>";
          } else {
            th.innerHTML += "<span class='sort_indicator'></span>";
          } 
        }
        
      }
      const fths = Array.from(this.sortableTableTarget.querySelectorAll('tfoot th'));
      for (const [i, fth] of fths.entries()) {
        if (fth.classList.contains('filter')) {
          if (!fth.innerHTML.includes("select")) {
          fth.innerHTML += '<select data-index="'+i+'", data-target="table.columnSelect" data-action="table#columnFilter"><option value=""></option></select>'
          }
        } else {
          if (!fth.innerHTML.includes("placeholder")) {
          fth.innerHTML += '<input type="text" placeholder="Search"  data-target="table.columnInput" data-index="'+i+'" data-action="keyup->table#columnFilter"/>'
          }
        }
      }
      if (this.columnSelectTargets.length > 0) {
        this.fillSelects(this.columnSelectTargets);
      }
      if (this.columnInputTargets.length > 0) {
        this.fillInputs(this.columnInputTargets);
      }
      this.filterColumns();
      if (this.searchInputTargets.length > 0) {
        let stored = sessionStorage.getItem(this.data.get("tablename") + "searchvalue");
        if(window.location.href.indexOf("tool_number") > -1) {
          const queryString = window.location.search;
          
          const urlParams = new URLSearchParams(queryString);
          const tool = urlParams.get('tool_number')
          stored = tool;
        }

        if (stored) {
          let rows = this.sortableTableTarget.querySelectorAll('tbody tr');
          let value = stored.trim().toLowerCase();
          if (value.length > 0) {
            this.searchInputTarget.value = stored;
            this.filterRows(rows, value);
          } else {
            rows.forEach(r => r.classList.remove('hidden'));
          }
        }
        if (this.searchInputTarget && this.searchInputTarget.value != '') {
          let rows = this.sortableTableTarget.querySelectorAll('tbody tr');
          let value = this.searchInputTarget.value.trim().toLowerCase();
          if (value.length > 0) {
            this.filterRows(rows, value);
          } else {
            rows.forEach(r => r.classList.remove('hidden'));
          }
        }
      }
      let storedSortDir = sessionStorage.getItem(this.data.get("tablename") + "|sortdir");
      let storedSortCol = sessionStorage.getItem(this.data.get("tablename") + "|sortcol");
      if (storedSortCol && storedSortDir) {
        let colNum = parseInt(storedSortCol) + 1;
        this.initialSort(storedSortCol, storedSortDir, this.sortableTableTarget.querySelector('th:nth-child('+colNum+')'));
      }
      if ((this.data.get("tablename") == "performance_converter_kitsTable1") && (window.location.href.indexOf("gm_damperless_multi-lockups") > -1)) {
        this.columnStoredFilter(5, "No".toLowerCase());
        this.columnStoredFilter(7, "Multi-Plate Lockup".toLowerCase());
        this.columnStoredFilter(2, "GM".toLowerCase());
      }
      if (this.data.get("tablename") == "ring_gearsTable1") {
        if(window.location.href.indexOf("stock") > -1) {
          this.columnStoredFilter(0, "Stock Flywheel/Flexplate".toLowerCase());
        }
        else if(window.location.href.indexOf("so") > -1) {
          this.columnStoredFilter(0, "Special Order".toLowerCase());
        }
        else if(window.location.href.indexOf("tcrg") > -1) {
          this.columnStoredFilter(0, "Torque Converter".toLowerCase());
        }
      }
      if (this.data.get("tablename") == "tech_specsTable1") {
        const table = this.sortableTableTarget;
        const user = table.dataset.user;
        const ac = parseInt(table.dataset.assigmentCount);
        const wrapper = document.getElementById("noTechSpecs");
        if(window.location.href.indexOf("assignments") > -1) {
          if (ac > 0) {
            this.columnStoredFilter(1, user.toLowerCase());
          }
          else {
            wrapper.innerHTML = "<h2 class='col-12 subhead'>You don't have any TSPs assigned to you.</h2>";
          }
        };
      }
    }
    this.data.set('initialized', true);
    
  }

  searchTable(e) {
  	let rows = this.sortableTableTarget.querySelectorAll('tbody tr');
  	let value = e.target.value.trim().toLowerCase();
  	if (value.length > 0) {
  		this.filterRows(rows, value);
  	} else {
  		rows.forEach(r => r.classList.remove('hidden'));
  	}
    sessionStorage.setItem(this.data.get("tablename") + "searchvalue", e.target.value);
  }

  searchHiddenTable(e) {
    if (e.target.value != "") {
      this.sortableTableTarget.style.height = 'auto';
      this.sortableTableTarget.classList.remove('hidden');
    } else {
      this.sortableTableTarget.style.height = '0px';
      this.sortableTableTarget.classList.add('hidden');
    }
    this.searchTable(e);
  }

  filterRows(rows, value) {
  	rows.forEach(r => {
  		let copy = '';
  		for (let td of Array.from(r.querySelectorAll('td'))) {
  			copy += td.innerText.toLowerCase();
  		}
  		if (copy.includes(value)) {
  			r.classList.remove('hidden');
  		} else {
  			r.classList.add('hidden');
  		}
  	});
  }

  fillInputs(inputs) {
    inputs.forEach(x => {
      let idx = x.dataset.index;
      let stored = sessionStorage.getItem(this.data.get("tablename") + "|column" + idx);
      if (stored) {
        x.value = stored;
        this.columnStoredFilter(idx, stored.toLowerCase());
      }
    });
  }

  fillSelects(selects) {
    let tbody = this.sortableTableTarget.querySelector('tbody');
    let trs = Array.from(tbody.querySelectorAll('tr'));
    let tds = Array.from(tbody.querySelectorAll('td'));
    const getCellValue = (tr, index) => tr.children[index].innerText.trim() || tr.children[index].textContent.trim();
    selects.forEach(s => {
      let idx = s.dataset.index;
      let currentoptions = Array.from(s.querySelectorAll('option')).map(o => o.value.trim());
      let newoptions = [];
      trs.forEach(tr => newoptions.push(getCellValue(tr, idx)));
      const options = newoptions.filter(o => !currentoptions.includes(o));
      options.reduce((acc, val) => acc.concat(val), []).filter(Boolean).filter((v, i, a) => a.indexOf(v) === i).sort().forEach(o => s.innerHTML += "<option value='"+o+"'>"+o+"</option>");
      let stored = sessionStorage.getItem(this.data.get("tablename") + "|column" + idx);
      if (stored) {
        let so = s.querySelector('option[value="'+stored+'"]');
        s.value = stored;
        this.columnStoredFilter(idx, stored.toLowerCase());
      }
    });
  }

  columnFilter(e) {
    sessionStorage.setItem(this.data.get("tablename") + "|column" + e.target.dataset.index, e.target.value);
    this.filterColumns();
  }

  filterColumns() {
    const searchValues = [];
    this.columnSelectTargets.forEach(s => {
      let idx = s.dataset.index;
      let value = s.value.toLowerCase();
      
      if (value != '') {
        const select = {};
        select.idx = idx;
        select.val = value;
        searchValues.push(select)
      }
    });
    this.columnInputTargets.forEach(s => {
      let idx = s.dataset.index;
      let value = s.value.toLowerCase();
      
      if (value != '') {
        const select = {};
        select.idx = idx;
        select.val = value;
        searchValues.push(select)
      }
    });

    const getCellValue = (tr, index) => tr.children[index].innerText || tr.children[index].textContent;
    this.sortableTableTarget.querySelectorAll('tbody tr').forEach(tr => {
      let visible = true;
      searchValues.forEach(sv => {
        let idx = sv.idx;
        let value = sv.val;
        let copy = tr.children[idx].innerText || tr.children[idx].textContent;
        if (!copy.toLowerCase().includes(value)) {
          visible = false; 
        }
      });
      if (visible == true) {
        tr.classList.remove('hidden');
      } else {
        tr.classList.add('hidden');
      }
    });
  }

  columnStoredFilter(idx, value) {
    const getCellValue = (tr, index) => tr.children[index].innerText || tr.children[index].textContent;
    this.sortableTableTarget.querySelectorAll('tbody tr').forEach(tr => {
      let copy = tr.children[idx].innerText || tr.children[idx].textContent;
      if (copy.toLowerCase().includes(value)) {
        tr.classList.remove('hidden');
      } else {
        tr.classList.add('hidden');
      }
    });
  }

  initialSort(idx, sortOrder, th) {
    let tbody = this.sortableTableTarget.querySelector('tbody');
    let trs = Array.from(tbody.querySelectorAll('tr'));
    let tds = Array.from(tbody.querySelectorAll('td'));
    const getCellValue = (tr, index) => tr.children[index].innerText || tr.children[index].textContent;
    const comparer = (index, asc) => (a, b) => ((v1, v2) => 
    v1 !== '' && v2 !== '' && !isNaN(parseFloat(v1)) && !isNaN(parseFloat(v2)) ? parseFloat(v1) - parseFloat(v2) : v1.toString().localeCompare(v2)
    )(getCellValue(asc ? a : b, index), getCellValue(asc ? b : a, index));
    
    let siblings = this.getSiblings(th);
    siblings.forEach(s => {
      if(!s.classList.contains('nosort')) {
        s.querySelector('.sort_indicator').innerHTML = "&varr;"
      }
    });
    if (sortOrder == "desc") {
      th.dataset.sort = "asc";
      th.querySelector('.sort_indicator').innerHTML = "&darr;";
      this.asc = true;
    } else {
      th.dataset.sort = "desc";
      th.querySelector('.sort_indicator').innerHTML = "&uarr;";
      this.asc = false;
    }
    trs.sort(comparer(idx, this.asc = !this.asc)).forEach(tr => tbody.appendChild(tr));
  }

  sortTable(e) {
  	let tbody = this.sortableTableTarget.querySelector('tbody');
  	let trs = Array.from(tbody.querySelectorAll('tr'));
  	let sortOrder = e.target.dataset.sort;
  	let idx = e.target.dataset.index;
  	let tds = Array.from(tbody.querySelectorAll('td'));
  	let siblings = this.getSiblings(e.target);
  	siblings.forEach(s => {
      if(!s.classList.contains('nosort')) {
  		  s.querySelector('.sort_indicator').innerHTML = "&varr;"
      }
  	});
  	const getCellValue = (tr, index) => tr.children[index].innerText || tr.children[index].textContent;
  	const comparer = (index, asc) => (a, b) => ((v1, v2) => 
    v1 !== '' && v2 !== '' && !isNaN(parseFloat(v1) && isFinite(v1)) && !isNaN(parseFloat(v2) && isFinite(v2)) ? parseFloat(v1) - parseFloat(v2) : v1.toString().localeCompare(v2)
    )(getCellValue(asc ? a : b, index), getCellValue(asc ? b : a, index));
  	trs.sort(comparer(idx, this.asc = !this.asc)).forEach(tr => tbody.appendChild(tr));
  	
		if (sortOrder == "desc") {
			e.target.dataset.sort = "asc";
			e.target.querySelector('.sort_indicator').innerHTML = "&darr;";
		} else {
			e.target.dataset.sort = "desc";
			e.target.querySelector('.sort_indicator').innerHTML = "&uarr;";
		}
    sessionStorage.setItem(this.data.get("tablename") + "|sortdir", sortOrder);
    sessionStorage.setItem(this.data.get("tablename") + "|sortcol", idx);
  }

  getSiblings(elem) {
		return Array.prototype.filter.call(elem.parentNode.children, function (sibling) {
			return sibling !== elem;
		});
	}

  clearState() {
    this.searchInputTarget.value = "";
    this.columnSelectTargets.forEach(s => s.value = "");
    this.columnInputTargets.forEach(s => s.value = "");
    let rows = this.sortableTableTarget.querySelectorAll('tbody tr');
    rows.forEach(r => r.classList.remove('hidden'));
  }

}