/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import Turbolinks from 'turbolinks';
import $ from 'jquery';

import "core-js/stable";
import "regenerator-runtime/runtime";

Turbolinks.start();
require("@rails/ujs").start()

import cocoon from 'cocoon-js';
import underscore from 'underscore';

import "controllers";

import lazyload from '../scripts/lazyload';
import directUpload from '../scripts/fileupload';
import ga from '../scripts/ga';
import responsiveTables from '../scripts/tech_resource_tables';
import slider from '../scripts/bxslider';
import autocomplete from '../scripts/easy-autocomplete';
import select2 from '../scripts/select2';
import printToPdf from '../scripts/print_to_pdf';
import timeIsMoney from '../scripts/time-is-money';
import datatables from '../scripts/datatables';
import "chartkick"


//navigation
$(document).on('turbolinks:load',function(event) {
	$(".tm").click(function(e) {
    if (e.target.checked === true) {
      $('.tm').not(this).each(function(){
        $(this).prop("checked", false);
     });
    }
	});
});
// still navigation
$(document).mouseup(function(e) {
  var menu = $("li.hovered");
  if (!menu.is(e.target) && menu.has(e.target).length === 0) {
    $(menu).removeClass("hovered");
  }
});

//turbolinks handling anchor links including scroll to top button
$(document).on('turbolinks:click', function (event) {
  if (event.target.getAttribute('href').charAt(0) === '#') {
    return event.preventDefault();
  }
});
$(document).on('turbolinks:load',function(event) {
	if (window.location.hash){
    var tags = document.getElementsByName(window.location.hash.slice(1));
    if(tags.length > 0){
      tags[0].scrollIntoView(true);
    }
  }
  $(window).scroll(function() {
    if ($(this).scrollTop() >= 50) {        // If page is scrolled more than 50px
        $('#return-to-top').fadeIn(200);    // Fade in the arrow
    } else {
        $('#return-to-top').fadeOut(200);   // Else fade out the arrow
    }
  });
  //force non-sonnax links to open in new tab
  $('a[href]:not([href^="mailto\\:"], [href^="javascript"])').each(function() {
     var a = new RegExp('/' + window.location.host + '/');
     if(!a.test(this.href)) {
         $(this).click(function(event) {
             event.preventDefault();
             event.stopPropagation();
             window.open(this.href, '_blank');
         });
     }
  });
});

//initializing scripts
$(document).on('turbolinks:load',function(event) {
  //analytics
  if (typeof gtag === 'function') {
    ga();
  }

  //autocomplete
  autocomplete();

  //lazyload images
  if ($('.lazy')) {
    lazyload();
  }

  //upload files to s3
  if ($('.directUpload')) {
    directUpload();
  }

  //hotjar
  if (typeof hj === 'function') {
    hj('stateChange', window.location.href);
  }

  //make tech article table responsive
  if ($('.tech-article table')) {
    responsiveTables();
  }

  //slider
  if ($('.slider, .timSlider')) {
    slider();
  }

  //print to pdf
  if ($('#printExView', "#time_is_money_pdf")) {
    printToPdf();
  }

  //time is money
  $('form#time_is_money').change(function(e) {
    timeIsMoney();
  }).click(function(e) {
    timeIsMoney();
  });
});

  //select2
  $(document).on('turbolinks:load', function() { 
    let loops = 30;
    for (let i = 0; i < loops; i++){
      $('#tech_spec_symptoms__name' + i).select2();
      $('#tech_spec_unit_relationships__unit_id' + i).select2();
      $('#tech_spec_dimensions__name' + i).select2();
      $('#tech_spec_dimensions__name_required' + i).select2();
      $('#tech_spec_tech_resources__existing_resource' + i).select2();
      $('#part_symptoms__name' + i).select2();
      $('#part_unit_relationships__unit_id' + i).select2();
      $('#part_dimensions__name' + i).select2();
    }
  });

  //datatables

  $(document).ready( function () {
    $('#users-list').DataTable( {
      "pageLength": 100,
      columnDefs: [
        { targets: 'no-sort', orderable: false }
      ]
    } );
  } );
