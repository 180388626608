import "core-js/stable";
import "regenerator-runtime/runtime";

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    	alert("This page will NOT work as expected in Internet Explorer! Please use Chrome/Firefox/Edge instead.");
    }
  }
}