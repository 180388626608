export default function ga() {
  if ($("#analytics_pn").length){
    gtag('config', 'UA-2838819-1', {'page_location': event.data.url, 'optimize_id': 'GTM-N9X65GR', 'custom_map': {'dimension1': 'partNumber', 'dimension2': 'category'}});
    
    var partNumber = $('#analytics_pn').data("part-name");
    var productLine = $('#prod_cat').data("product-category");
    
    gtag('event', 'partDimensions', {'partNumber': partNumber, category: productLine, 'non_interaction': true});
  }
  else if ($("#analytics_cat").length) {
    gtag('config', 'UA-2838819-1', {'page_location': event.data.url, 'optimize_id': 'GTM-N9X65GR', 'custom_map': {'dimension2': 'category'}});
    var webCategory = $('#analytics_cat').data("category");
    gtag('event', 'catDimensions', {category: webCategory, 'non_interaction': true});
  }
  else {
    gtag('config', 'UA-2838819-1', {'page_location': event.data.url, 'optimize_id': 'GTM-N9X65GR'});
  };
  $('#new_feedback').submit(function(){
    gtag('event', 'submittedFeedback');
  });
  $('#distributors_link').click(function(){
    gtag('event', 'findDistributor', {'event_category': 'Product Category', 'event_label': $('#prod_cat').data("product-category")});
  });
  $('#fill_out_form').click(function(){
    gtag('event', 'fillInForm', {'event_category': 'Product Category', 'event_label': $('#prod_cat').data("product-category")});
  });
  if ($('div.wistia_responsive_padding').length) {
      window.wistiaInit = function(W) {
        $('div.wistia_embed').each(function() {
          var matcher = $(this).attr("id");
          setTimeout(videoLoad, 1000);
          function videoLoad() {
            var videoName = W.api(matcher).name();
            gtag('event', 'Loaded', {'event_category': 'Video','event_label': videoName, 'non_interaction': true});
          }
        });
      };
    }
};