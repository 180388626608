import "core-js/stable";
import "regenerator-runtime/runtime";

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "email", "submitButton", "warning" ]

  check() {
    if (this.emailTarget.value.split('@').slice(1) != 'sonnax.com') {
    	this.warningTarget.classList.remove("hidden");
    	this.submitButtonTarget.disabled = true;
    } else {
    	this.warningTarget.classList.add("hidden");
    	this.submitButtonTarget.disabled = false;
    }
  }
}